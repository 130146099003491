export const POSITION_FOR_SLEEPING = [
  { label: "うつ伏せ", value: 0 , label_kr: "엎드림", label_tw: "俯臥"},
  { label: "左向き", value: 3 , label_kr: "좌향", label_tw: "左側臥"},
  { label: "右向き", value: 1 , label_kr: "우향", label_tw: "右側臥"},
  { label: "直立", value: 4 , label_kr: "직립", label_tw: "直立"},
  { label: "左斜め", value: 9 , label_kr: "좌측사선", label_tw: "左斜臥"},
  { label: "仰向け", value: 2 , label_kr: "위를 향하다", label_tw: "仰臥"},
  { label: "右斜め", value: 8 , label_kr: "우측사선", label_tw: "右斜臥"},
  { label: "姿勢異常", value: 5 , label_kr: "자세 이상", label_tw: "姿勢異常"},
];

export const pageOptions = [
  { label: 15, value: 15 },
  { label: 30, value: 30 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const SECRET_IV_KEY = process.env.REACT_APP_SECRET_IV_KEY;
export const FORCE_HTTPS = process.env.REACT_APP_FORCE_HTTPS;
export const ERRORS_CODE = {
  ERROR_400: 400,
  ERROR_401: 401,
  ERROR_422: 422,
  ERROR_409: 409,
  ERROR_500: 500,
};
